import { EntityUIQuery, Order, QueryConfig, QueryEntity } from "@datorama/akita";
import { ChannelsState, ChannelsStore, channelsStore, ChannelUIState } from "./channels.store";

@QueryConfig({
    sortBy: "inserted_at",
    sortByOrder: Order.ASC
})
export class ChannelsQuery extends QueryEntity<ChannelsState> {
    ui!: EntityUIQuery<ChannelUIState>;

    constructor(protected store: ChannelsStore) {
        super(store);
        this.createUIQuery();
    }

    isOwner(channelId: string) {
        const channel = this.getEntity(channelId);

        if (!channel) {
            return false;
        }

        return channel.isOwner;
    }
}

export const channelsQuery = new ChannelsQuery(channelsStore);
