import {
    faBackward,
    faForward,
    faList,
    faRandom,
    faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { playerQuery, playerService, Track } from "../../state/player";
import { postsQuery } from "../../state/posts";
import { WebPlaybackPlayer } from "../../types/spotify";
import { Playlist } from "../Playlist/Playlist";
import { PlayButton } from "./PlayButton";
import "./player.scss";

interface PlayerProps {
    channelId: string;
    player?: WebPlaybackPlayer;
}
export const Player: React.FC<PlayerProps> = ({ channelId, player }) => {
    const [isPlaylistVisible, togglePlaylist] = usePlaylistState(false);
    const [isPlaying, setPlaying] = useState(playerQuery.isPlaying);
    const [isShuffle, toggleShuffle] = useState(playerQuery.isShuffle);
    const [currentTrack, setCurrentTrack] = useState<Track | undefined>();

    const togglePlaylistIcon = isPlaylistVisible ? faTimes : faList;

    const renderPlaylist = isPlaylistVisible && <Playlist channelId={channelId} />;

    const play = () => {
        if (currentTrack) {
            playerService.play();
            return;
        }

        const tracks = postsQuery.getFromChannel(channelId).map((p) => p.track);

        playerService.play(tracks);
    };

    const pause = () => player && player.pause();
    const next = () => player && player.nextTrack();
    const prev = () => player && player.previousTrack();
    const shuffle = (state: boolean) => playerService.shuffle(state);

    useEffect(() => {
        const playerState$ = playerQuery.select().subscribe((state) => {
            setPlaying(state.isPlaying);
            setCurrentTrack(state.track);
            toggleShuffle(state.shuffle);
        });

        return () => playerState$.unsubscribe();
    }, [channelId]);

    return (
        <div className="player is-fluid">
            <div className="top-controls">
                <div className="controls">
                    <div className="player-control back" onClick={prev}>
                        <FontAwesomeIcon icon={faBackward} />
                    </div>

                    <PlayButton isPlaying={isPlaying} play={play} pause={pause} />

                    <div className="player-control forward" onClick={next}>
                        <FontAwesomeIcon icon={faForward} />
                    </div>
                </div>

                {currentTrack && currentTrack.artists && (
                    <div>
                        {currentTrack.artists} - {currentTrack.song}
                    </div>
                )}

                <div>
                    <div className="controls">
                        <div
                            className={`player-control back${isShuffle ? " on" : ""}`}
                            onClick={() => shuffle(!isShuffle)}
                        >
                            <FontAwesomeIcon icon={faRandom} />
                        </div>
                        <div className="player-control play" onClick={() => togglePlaylist()}>
                            <FontAwesomeIcon icon={togglePlaylistIcon} />
                        </div>
                    </div>
                </div>
            </div>
            {renderPlaylist}
        </div>
    );
};

function usePlaylistState(initialState: boolean): [boolean, Function] {
    const [isPlaylistVisible, setPlaylistVisible] = useState(initialState);

    function togglePlaylist() {
        setPlaylistVisible(!isPlaylistVisible);
    }

    return [isPlaylistVisible, togglePlaylist];
}
