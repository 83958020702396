import { commentsStore, CommentsStore } from ".";
import { get, post } from "../../api";
import { postsStore } from "../posts";
import { Comment } from "./comment.model";

export class CommentsService {
    constructor(protected commentsStore: CommentsStore) {}

    getForPost(channelId: string, postId: string) {
        get<Comment[]>(`/channels/${channelId}/posts/${postId}/comments`).then((res) => {
            this.commentsStore.upsertMany(res.data);
            postsStore.ui.update(postId, { hasLoadedComments: true });
        });
    }

    create(channelId: string, postId: string, data: Partial<Comment>) {
        return post(`/channels/${channelId}/posts/${postId}/comments`, { comment: data }).then(
            (res) => {
                const comment = res.data as Comment;

                this.commentsStore.upsert(comment.id, comment);

                postsStore.update(postId, (post) => ({ comments: [...post.comments, comment] }));
                postsStore.ui.update(postId, { hasLoadedComments: true });
            }
        );
    }
}

export const commentsService = new CommentsService(commentsStore);
