import { channelsStore, ChannelsStore, Channel } from ".";
import { post, get, del } from "../../api";

export class ChannelsService {
    constructor(private channelsStore: ChannelsStore) {}

    getAll() {
        return get<Channel[]>("/channels").then((response) => {
            this.channelsStore.set(response.data);
        });
    }

    create(newChannel: Partial<Channel>) {
        return post("/channels", { channel: newChannel }).then((response) => {
            const channel = response.data as Channel;

            return this.getAll().then(() => {
                return Promise.resolve(channel.id);
            });
        });
    }

    delete(channelId: string) {
        return del(`/channels/${channelId}`).then(() => {
            return this.getAll();
        });
    }

    inviteUser(channelId: string, userId: string) {
        return post(`/channels/${channelId}/invite/${userId}`);
    }
}

export const channelsService = new ChannelsService(channelsStore);
