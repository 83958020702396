import { Order, QueryConfig, QueryEntity } from "@datorama/akita";
import { CommentsState, CommentsStore, commentsStore } from "./comments.store";

@QueryConfig({
    sortBy: "inserted_at",
    sortByOrder: Order.DESC
})
export class CommentsQuery extends QueryEntity<CommentsState> {
    constructor(protected store: CommentsStore) {
        super(store);
    }

    selectFromPost = (postId: string) => {
        return this.selectAll({
            filterBy: (c) => String(c.postId) === postId
        });
    };
}

export const commentsQuery = new CommentsQuery(commentsStore);
