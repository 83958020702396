import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import React, { useState } from "react";
import { RouteProps } from "react-router-dom";
import Logo from "../../components/Logo";
import { BaseModal } from "../../components/Modal";
import history from "../../history";
import { sessionQuery } from "../../state/session";
import "./login.scss";

enum Error {
    NO_PREMIUM = "NO_PREMIUM",
    OAUTH_ERROR = "OAUTH_ERROR"
}

interface LoginProps extends RouteProps {}

export const Login: React.FC<LoginProps> = (props: LoginProps) => {
    const [modalVisible, toggleModal] = useState(false);

    const queryParams: ErrorMessageProps = {
        error: null
    };

    let loginCode = null;

    if (props.location) {
        queryParams.error = queryString.parse(props.location.search).error;
        loginCode = queryString.parse(props.location.search).code;
    }

    if (sessionQuery.userId) {
        history.push("/");
    }

    const modal = modalVisible && (
        <BaseModal
            title="Why Spotify Premium?"
            body="Spotify requires a premium subscription to stream music in 3rd party apps. This way artists will get paid when you listen to Volatile Frequencies."
            actions={
                <button className="button is-vf" onClick={() => toggleModal(false)}>
                    Got it
                </button>
            }
            onDismiss={() => toggleModal(false)}
        />
    );

    const whyMessage = !queryParams.error && (
        <p className="why" onClick={() => toggleModal(true)}>
            Why is Spotify Premium Required?
        </p>
    );

    let loginUrl = `${process.env.REACT_APP_VF_API}/auth/spotify`;

    if (loginCode) {
        loginUrl += `?state=${loginCode}`;
    }

    return (
        <div className="login">
            <div className="top">
                <Logo />
            </div>

            <ErrorMessage error={queryParams.error} />

            {whyMessage}

            <a className="button is-primary is-medium is-fullwidth" href={loginUrl}>
                <span className="icon">
                    <FontAwesomeIcon icon={faSpotify} />
                </span>
                <span>Login with Spotify Premium</span>
            </a>

            {modal}
        </div>
    );
};

interface ErrorMessageProps {
    error?: string | string[] | null;
}
const ErrorMessage: React.FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
    if (!props.error) {
        return null;
    }

    let errorMessage = "";

    switch (props.error) {
        case Error.NO_PREMIUM:
            errorMessage = "You can only login with a Spotify Premium account.";
            break;

        default:
            errorMessage = "Hmmm, something went wrong. Please try again.";
            break;
    }

    return <span className="error">{errorMessage}</span>;
};
