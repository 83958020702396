import React from "react";
import ReactDOM from "react-dom";
import "./base-modal.scss";

interface BaseModalProps {
    title?: string;
    body?: string | JSX.Element;
    actions?: JSX.Element;
    hideClose?: boolean;
    hideBackground?: boolean;
    className?: string;
    onDismiss: () => void;
}
export const BaseModal: React.FC<BaseModalProps> = (props: BaseModalProps) => {
    const rootModalElement = document.querySelector("#root-modal");

    if (!rootModalElement) {
        return null;
    }

    const { title, body, actions, hideClose, hideBackground, onDismiss, className } = props;

    return ReactDOM.createPortal(
        <div className={`modal is-active ${className}`} onClick={onDismiss}>
            {!hideBackground && <div className="modal-background"></div>}

            <div className="modal-card" onClick={(e) => e.stopPropagation()}>
                {/* HEADER */}
                {(!hideClose || title) && (
                    <header className="modal-card-head">
                        <p className="modal-card-title">{title}</p>
                        {!hideClose && (
                            <button
                                className="delete"
                                aria-label="close"
                                onClick={onDismiss}
                            ></button>
                        )}
                    </header>
                )}

                {/* BODY */}
                {body && <section className="modal-card-body">{body}</section>}

                <footer className="modal-card-foot">{actions}</footer>
            </div>
        </div>,
        rootModalElement
    );
};
