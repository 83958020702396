import { Store, StoreConfig } from "@datorama/akita";
import { User } from "../users/user.model";

export interface SessionState {
    user?: User;
    authenticated: boolean;
}

export function createInitialState(): SessionState {
    return {
        authenticated: false
    };
}

@StoreConfig({ name: "session" })
export class SessionStore extends Store<SessionState> {
    constructor() {
        super(createInitialState());
    }
}

export const sessionStore = new SessionStore();
