import React, { useState, SyntheticEvent } from "react";
import { commentsService } from "../../state/comments";

interface CommentInputProps {
    postId: string;
    channelId: string;
}
export const CommentInput: React.FC<CommentInputProps> = ({ postId, channelId }) => {
    const [input, setInput] = useState("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        if (!input.length) {
            return;
        }

        commentsService.create(channelId, postId, { message: input }).then(() => setInput(""));
    };

    return (
        <div className="field">
            <label className="label">Comment</label>
            <div className="control">
                <form onSubmit={handleSubmit}>
                    <input
                        className="input"
                        id="comment"
                        type="text"
                        autoComplete="off"
                        autoFocus
                        value={input}
                        onChange={handleInputChange}
                        maxLength={500}
                    />
                </form>
            </div>
        </div>
    );
};
