import { del, get } from "../../api";
import history from "../../history";
import { User } from "../users/user.model";
import { sessionStore } from "./session.store";

export class SessionService {
    getUser() {
        return get<User>("/me");
    }

    authenticate(user: User) {
        sessionStore.update({ user, authenticated: true });
    }

    unauthenticate() {
        del("/logout").then(() => {
            sessionStore.reset();
            history.push("/login");
        });
    }
}

export const sessionService = new SessionService();
