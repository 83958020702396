import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { AddUserModal, DeleteModal, SubmitModal, InviteModal } from "../../components/Modal";
import { Player, usePlayer } from "../../components/Player";
import { Post } from "../../components/Post";
import history from "../../history";
import { channelsQuery, channelsService } from "../../state/channels";
import { Post as IPost, postsQuery, postsService, SortedBy } from "../../state/posts";
import "./channel.scss";

interface ChannelParams {
    id: string;
}
interface ChannelProps extends RouteComponentProps<ChannelParams> {}
export const Channel: React.FC<ChannelProps> = (props: ChannelProps) => {
    const [posts, setPosts] = useState<IPost[]>([]);

    const [submitModalVisible, toggleSubmitModal] = useState(false);
    const [deleteModalVisible, toggleDeleteModal] = useState(false);
    const [addUserModalVisible, toggleAddUserModal] = useState(false);
    const [inviteModalVisible, toggleInviteModal] = useState(false);

    const [player] = usePlayer();

    const channelId = props.match.params.id;

    useEffect(() => {
        postsService.getAll(channelId);

        const posts$ = postsQuery
            .selectFromChannel(channelId, SortedBy.Recent)
            .subscribe((posts) => setPosts(posts));

        return () => posts$.unsubscribe();
    }, [channelId]);

    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        const channel$ = channelsQuery
            .selectEntity(channelId)
            .subscribe((c) => setIsAdmin(c && c.isOwner));

        return () => channel$.unsubscribe();
    });

    const submitTrack = (params: Partial<IPost>) => {
        postsService.create(channelId, params).then(() => {
            toggleSubmitModal(false);
        });
    };

    const deleteChannel = () => {
        channelsService.delete(channelId).then(() => {
            toggleDeleteModal(false);
            history.push("/");
        });
    };

    const inviteNewUsers = () => {
        toggleInviteModal(true);
        toggleAddUserModal(false);
    };

    const createModal = submitModalVisible && (
        <SubmitModal toggleSubmitModal={toggleSubmitModal} submitTrack={submitTrack} />
    );

    const deleteModal = deleteModalVisible && (
        <DeleteModal toggleDeleteModal={toggleDeleteModal} deleteChannel={deleteChannel} />
    );

    const addUserModal = addUserModalVisible && (
        <AddUserModal
            toggleAddUserModal={toggleAddUserModal}
            inviteModal={inviteNewUsers}
            channelId={channelId}
        />
    );

    const inviteModal = inviteModalVisible && (
        <InviteModal toggleModal={toggleInviteModal} channelId={channelId} />
    );

    const renderPosts = posts.map((post) => (
        <Post post={post} key={post.id} player={player} channelId={channelId} />
    ));

    return (
        <>
            <Player channelId={channelId} player={player} />
            <div className="container is-fluid">
                <div className="columns">
                    <div className="column is-8 is-offset-3">
                        <div className="columns">
                            <div className="column is-10 is-offset-1 top-actions">
                                <div className="left">
                                    <Link to="#" onClick={() => toggleSubmitModal(true)}>
                                        + SUBMIT A TRACK
                                    </Link>

                                    {isAdmin && (
                                        <Link to="#" onClick={() => toggleAddUserModal(true)}>
                                            + ADD USER
                                        </Link>
                                    )}
                                </div>

                                {isAdmin && (
                                    <Link
                                        to="#"
                                        className="has-text-danger"
                                        onClick={() => toggleDeleteModal(true)}
                                    >
                                        DELETE CHANNEL
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="posts">
                    <div className="columns">
                        <div className="column is-3"></div>
                        <div className="column is-8">{renderPosts}</div>
                    </div>
                </div>
            </div>

            {createModal}
            {deleteModal}
            {addUserModal}
            {inviteModal}
        </>
    );
};
