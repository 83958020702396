import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface PlayButtonProps {
    isPlaying: boolean;
    play: () => void;
    pause: () => void;
}
export const PlayButton: React.FC<PlayButtonProps> = ({ isPlaying, play, pause }) => {
    if (!isPlaying) {
        return (
            <div className="player-control play" onClick={play}>
                <FontAwesomeIcon icon={faPlay} />
            </div>
        );
    }

    return (
        <div className="player-control play" onClick={pause}>
            <FontAwesomeIcon icon={faPause} />
        </div>
    );
};
