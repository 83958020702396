import { faHeart } from "@fortawesome/free-regular-svg-icons";
import {
    faCompactDisc,
    faHeart as faHeartS,
    faPause,
    faPlay
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { channelsQuery, channelsStore } from "../../state/channels";
import { playerQuery, playerService } from "../../state/player";
import { Post, postsQuery, SortedBy } from "../../state/posts";
import { sessionQuery } from "../../state/session";
import "./playlist.scss";

interface PlaylistProps {
    channelId: string;
}
export const Playlist: React.FC<PlaylistProps> = ({ channelId }) => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [sortBy, setSortBy] = useSortBy(channelId);

    useEffect(() => {
        const posts$ = postsQuery
            .selectFromChannel(channelId, sortBy)
            .subscribe((p) => setPosts(p));

        return () => posts$.unsubscribe();
    }, [channelId, sortBy]);

    const playTracks = (track: string) => {
        const trackIndex = posts.findIndex((p) => p.track === track);
        const tracks = posts.map((p) => p.track);

        playerService.play(tracks.splice(trackIndex));
    };

    const isSortedClass = (s: string) => (s === sortBy ? "sorted" : "");

    const renderSongs = posts.map((p) => (
        <PlaylistSong key={p.id} post={p} onClick={() => playTracks(p.track)} />
    ));

    return (
        <div className="container playlist">
            <div className="columns">
                <div className="column is-9 is-offset-2">
                    <div className="sort-nav">
                        <span
                            className={isSortedClass(SortedBy.All)}
                            onClick={() => setSortBy(SortedBy.All)}
                        >
                            <FontAwesomeIcon icon={faCompactDisc} /> All
                        </span>
                        <span
                            className={isSortedClass(SortedBy.Liked)}
                            onClick={() => setSortBy(SortedBy.Liked)}
                        >
                            Liked
                        </span>
                        <span
                            className={isSortedClass(SortedBy.Recent)}
                            onClick={() => setSortBy(SortedBy.Recent)}
                        >
                            Most recent
                        </span>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-1 is-offset-1 stream-title">
                    <p>VF STREAM</p>
                </div>
                <div className="column is-9 song-list">
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th colSpan={2}>Track</th>
                                <th>Artist</th>
                                <th>Like</th>
                                <th>Submited by</th>
                            </tr>
                        </thead>
                        <tbody>{renderSongs}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

function useSortBy(channelId: string): [SortedBy, Function] {
    const [sortBy, setSortBy] = useState(SortedBy.All);

    useEffect(() => {
        const sortBy$ = channelsQuery.ui
            .selectEntity(channelId, "sortBy")
            .subscribe((s) => setSortBy(s));

        return () => sortBy$.unsubscribe();
    }, [channelId]);

    const setNewSortBy = (newSortBy: SortedBy) => {
        channelsStore.ui.update(channelId, { sortBy: newSortBy });
    };

    return [sortBy, setNewSortBy];
}

interface PlaylistSongProps {
    post: Post;
    onClick: () => void;
}
export const PlaylistSong: React.FC<PlaylistSongProps> = ({ post, onClick }) => {
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        const currentTrack$ = playerQuery.select().subscribe((player) => {
            const playingTrack = player.track;

            if (!playingTrack) {
                setPlaying(false);
                return;
            }

            setPlaying(player.isPlaying && playingTrack.track === post.track);
        });

        return () => currentTrack$.unsubscribe();
    }, [post.track]);

    const {
        song,
        artists,
        liked_by,
        user: { name: user }
    } = post;

    const userId = sessionQuery.userId;
    const heartIcon = liked_by.includes(userId) ? faHeartS : faHeart;
    const playButton = isPlaying ? faPause : faPlay;

    return (
        <tr onClick={onClick}>
            <td colSpan={2} className="track">
                <FontAwesomeIcon icon={playButton} /> {song}
            </td>
            <td>{artists}</td>
            <td>
                <FontAwesomeIcon icon={heartIcon} />
            </td>
            <td>[ {user} ]</td>
        </tr>
    );
};
