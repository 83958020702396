import { Post, postsStore, PostsStore } from ".";
import { get, post, put } from "../../api";
import { commentsStore } from "../comments";

export class PostsService {
    constructor(private postsStore: PostsStore) {}

    getAll(channelId: string) {
        return get<Post[]>(`/channels/${channelId}/posts`).then((res) => {
            const posts = res.data;
            this.postsStore.upsertMany(posts);

            posts.forEach((post) => {
                commentsStore.upsertMany(post.comments);

                this.postsStore.ui.update(post.id, { hasLoadedComments: post.comments.length > 0 });
            });
        });
    }

    create(channelId: string, newPost: Partial<Post>) {
        return post(`/channels/${channelId}/posts`, { post: newPost }).then(() => {
            return this.getAll(channelId);
        });
    }

    validate<T>(url: string) {
        return post<T>("/posts/validate", { url });
    }

    like(channelId: string, postId: string) {
        return put(`/channels/${channelId}/posts/${postId}/like`).then((res) => {
            const post = res.data as Post;
            this.postsStore.upsert(post.id, post);
        });
    }
}

export const postsService = new PostsService(postsStore);
