import { toast } from "react-toastify";
import { PlayerStore, playerStore } from ".";
import { post, put } from "../../api";
import { playerQuery } from "./player.query";
import { PlayerState } from "./player.store";

export class PlayerService {
    constructor(private playerStore: PlayerStore) {}

    play(tracks?: string[]) {
        post(`/player/play?device_id=${playerQuery.deviceId}`, { tracks }).catch((e) => {
            let message = "There was an unknown error.";

            if (
                !e.response ||
                !e.response.data ||
                !e.response.data.error ||
                !e.response.data.error.reason
            ) {
                toast(message, { type: "error" });
                return;
            }

            const reason = e.response.data.error.reason;

            switch (reason) {
                case "NO_ACTIVE_DEVICE":
                    message = "There is no Spotify client running.";
                    break;
                default:
                    message = e.response.data.error.reason;
            }

            toast(message, { type: "error" });
        });
    }

    shuffle(state: boolean) {
        put(`/player/shuffle?state=${state}`);
    }

    updatePlayer(state: Partial<PlayerState>) {
        this.playerStore.update(state);
    }
}

export const playerService = new PlayerService(playerStore);
