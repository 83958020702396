import React from "react";

interface UserIconProps {
    name: string;
    color?: "black" | "purple" | "green" | "darkblue" | "violet";
    image?: string;
}

export const UserIcon: React.FC<UserIconProps> = (props = { name: "" }) => {
    const initials = props.name ? getInitials(props.name) : "";
    const color = props.color || randomColor();

    function getInitials(name: string): string {
        const splittedName = getNames(name);
        const firstInital = splittedName[0].split("")[0];

        if (splittedName.length <= 1) {
            return firstInital;
        }

        const lastInital = splittedName[1].split("")[0];

        return `${firstInital}${lastInital}`;
    }

    if (props.image) {
        return (
            <div
                className={`user-icon user-image ${color}`}
                style={{
                    backgroundImage: `url(${props.image})`
                }}
            ></div>
        );
    }

    return (
        <div className={`user-icon ${color}`}>
            <span>{initials}</span>
        </div>
    );
};

export function getNames(name: string): string[] {
    const splittedName = name.split(" ");
    const firstName = splittedName[0];

    if (splittedName.length <= 1) {
        return [firstName];
    }

    const lastName = splittedName[splittedName.length - 1];

    return [firstName, lastName];
}

function randomColor(): string {
    const colors = ["black", "purple", "darkblue", "violet"];
    return colors[Math.floor(Math.random() * colors.length)];
}
