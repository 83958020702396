import axios from "axios";

const API = `${process.env.REACT_APP_VF_API}/api`;

function queryString(params: { [key: string]: any } = {}) {
    const query = Object.keys(params)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join("&");
    return `${query.length ? "?" : ""}${query}`;
}

export function get<T>(url: string, params = {}) {
    return axios.get<T>(`${API}${url}${queryString(params)}`, { withCredentials: true });
}

export function del<T>(url: string, params = {}) {
    return axios.delete<T>(`${API}${url}${queryString(params)}`, { withCredentials: true });
}

export function post<T>(url: string, params = {}) {
    return axios.post<T>(`${API}${url}`, params, { withCredentials: true });
}

export function put<T>(url: string, params = {}) {
    return axios.put<T>(`${API}${url}`, params, { withCredentials: true });
}
