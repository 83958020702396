import React, { useEffect, useState } from "react";
import { User } from "../state/users/user.model";
import { sessionQuery } from "../state/session";
import { Navbar } from "./Navbar";

export const Layout: React.FC = ({ children }) => {
    const [user, setUser] = useState<User | undefined>();
    const [userId, setUserId] = useState<number | undefined>();

    useEffect(() => {
        const session$ = sessionQuery.select().subscribe((state) => {
            setUser(state.user);
            setUserId(state.user && state.user.id);
        });

        return () => session$.unsubscribe();
    }, [userId]);

    return (
        <>
            {user && (
                <>
                    <Navbar user={user} />
                    <div className="main">{children}</div>
                </>
            )}
        </>
    );
};
