import { Query } from "@datorama/akita";
import { SessionStore, SessionState, sessionStore } from "./session.store";

export class SessionQuery extends Query<SessionState> {
    get isAuthenticated(): boolean {
        return this.getValue().authenticated;
    }

    get userId() {
        const user = this.getValue().user;
        return (user && user.id) || 0;
    }

    get spotifyToken() {
        const user = this.getValue().user;
        return (user && user.spotify_token) || "";
    }

    constructor(protected store: SessionStore) {
        super(store);
    }
}

export const sessionQuery = new SessionQuery(sessionStore);
