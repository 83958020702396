import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseModal } from "..";
import { userService } from "../../../state/users/user.service";
import { useDebounce, validateEmail } from "../../../utils";

interface InviteModalProps {
    channelId: string;
    toggleModal: (show: boolean) => void;
}
export const InviteModal: React.FC<InviteModalProps> = ({ channelId, toggleModal }) => {
    const [input, setInput] = useState("");
    const [isInvalid, setInvalid] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [hasJoined, setHasJoined] = useState(false);

    const debouncedTerm = useDebounce(input, 600);

    useEffect(() => {
        if (debouncedTerm) {
            const validEmail = validateEmail(debouncedTerm);

            if (!validEmail) {
                setLoading(false);
                setInvalid(true);
                return;
            }

            setInvalid(false);

            userService
                .checkEmail(debouncedTerm, channelId)
                .then((res) => setHasJoined(res))
                .catch()
                .then(() => setLoading(false));
        } else {
            setInvalid(false);
            setLoading(false);
        }
    }, [debouncedTerm, channelId]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        setInput(e.target.value);
    };

    const inviteUser = () => {
        setLoading(true);
        userService
            .invite(debouncedTerm, channelId)
            .then((res) => {
                setHasJoined(res);
            })
            .catch()
            .then(() => setLoading(false));
    };

    const renderHasJoined = () => {
        if (isLoading) {
            return <Link to="#" className="button is-loading" />;
        }

        if (hasJoined) {
            return (
                <Link to="#" className="button has-joined">
                    <FontAwesomeIcon icon={faCheck} />
                </Link>
            );
        }

        return (
            <Link to="#" className="button" onClick={inviteUser}>
                Invite
            </Link>
        );
    };

    const renderBody = (
        <>
            <div className="form">
                <div className="field">
                    <label className="label">INVITE NEW USERS BY EMAIL</label>
                </div>
                <div className="field has-addons">
                    <input
                        className={isInvalid ? "control input is-danger" : "control input"}
                        value={input}
                        onChange={handleInput}
                        type="text"
                        autoComplete="off"
                        autoFocus
                        placeholder="john@doe.com"
                    />

                    <p className={isInvalid ? "control is-danger" : "control"}>
                        {!isInvalid && input.length > 0 && renderHasJoined()}
                    </p>
                </div>
                <div className="field">
                    {isInvalid && (
                        <p className="help is-danger">This is not a valid email address.</p>
                    )}
                </div>
            </div>
        </>
    );

    return (
        <BaseModal
            body={renderBody}
            onDismiss={() => toggleModal(false)}
            hideBackground={true}
            className="invite-modal"
        />
    );
};
