import React, { useEffect, useState } from "react";
import { Post, postsService } from "../../../state/posts";
import { useDebounce } from "../../../utils";
import { BaseModal } from "../BaseModal/BaseModal";
import "./submit-modal.scss";

interface SubmitModalProps {
    toggleSubmitModal: (show: boolean) => void;
    submitTrack: (params: Partial<Post>) => void;
}

interface ValidatedTrack extends Partial<Post> {
    song: string;
    artists: string;
    url: string;
    track: string;
    error?: string;
}

interface ValidateResponse extends ValidatedTrack {
    valid: boolean;
}

interface SubmitPost extends Partial<ValidatedTrack> {
    url: string;
    message: string;
}

export const SubmitModal: React.FC<SubmitModalProps> = (props) => {
    const [post, setPost] = useState<SubmitPost>({ url: "", message: "" });
    const [isLoading, setLoading] = useState(false);
    const [track, setTrack] = useState<ValidatedTrack>();
    const [isInvalid, setInvalid] = useState<string | undefined>();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        setLoading(true);
        setPost({ ...post, [name]: value });
    };
    const debouncedLink = useDebounce(post.url, 600);

    useEffect(() => {
        if (debouncedLink) {
            postsService
                .validate<ValidateResponse>(debouncedLink)
                .then(({ data: { valid, song, artists, track, error } }) => {
                    if (valid) {
                        setTrack({ song, artists, url: debouncedLink, track });
                        setInvalid("");
                    } else {
                        setInvalid(error);
                    }

                    setLoading(false);
                })
                .catch(() => setLoading(false));
        } else {
            setInvalid("");
            setLoading(false);
        }
    }, [debouncedLink]);

    if (!track || !track.song) {
        return (
            <BaseModal
                title="Submit a track"
                body={
                    <div className="field">
                        <label className="label">Spotify link</label>
                        <div className={isLoading ? "control is-loading" : "control"}>
                            <input
                                className={isInvalid ? "input is-danger" : "input"}
                                name="url"
                                value={post.url}
                                onChange={handleInputChange}
                                type="text"
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                        {isInvalid && <p className="help is-danger">{isInvalid}</p>}
                    </div>
                }
                onDismiss={() => props.toggleSubmitModal(false)}
            />
        );
    }

    return (
        <BaseModal
            title="Submit a track"
            body={
                <>
                    <div className="track-preview">
                        <p>
                            {track.song} - {track.artists}
                        </p>
                    </div>

                    <div className="field">
                        <label className="label">TELL US WHY IT'S SPECIAL</label>
                        <div className="control">
                            <textarea
                                className="textarea"
                                name="message"
                                value={post.message}
                                onChange={handleInputChange}
                                rows={3}
                            ></textarea>
                        </div>
                    </div>
                </>
            }
            actions={
                <>
                    {post.message && (
                        <div className="actions">
                            <button
                                className="button is-vf"
                                onClick={() => props.toggleSubmitModal(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="button is-vf"
                                onClick={() => props.submitTrack({ ...post, ...track })}
                            >
                                Post
                            </button>
                        </div>
                    )}
                </>
            }
            onDismiss={() => props.toggleSubmitModal(false)}
        />
    );
};
