export function hightlight(term: string, content: string, wrapper = "mark"): string {
    const regexFlags = "gi";

    const wrap = (match: string): string => {
        return "<" + wrapper + ">" + match + "</" + wrapper + ">";
    };

    const regex = new RegExp(term, regexFlags);

    return content.replace(regex, (match) => wrap(match));
}
