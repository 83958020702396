import { EntityUIQuery, Order, QueryConfig, QueryEntity } from "@datorama/akita";
import { sessionQuery } from "../session";
import { Post } from "./post.model";
import { PostsState, PostsStore, postsStore, PostsUIState } from "./posts.store";

export enum SortedBy {
    All = "All",
    Liked = "Liked",
    Recent = "Recent"
}

@QueryConfig({
    sortBy: "inserted_at",
    sortByOrder: Order.ASC
})
export class PostsQuery extends QueryEntity<PostsState> {
    ui!: EntityUIQuery<PostsUIState>;

    constructor(protected store: PostsStore) {
        super(store);
        this.createUIQuery();
    }

    getFromChannel = (channelId: string, sorted = SortedBy.Recent) => {
        // Defaults to most recent
        let sortBy = sortByRecent;

        if (sorted === SortedBy.All) {
            sortBy = sortBySong;
        }

        return this.getAll({
            filterBy: (p) => {
                const isFromChannel = String(p.channel_id) === channelId;

                if (!isFromChannel) {
                    return false;
                }

                const isSortedByLiked = sorted === SortedBy.Liked;
                if (!isSortedByLiked) {
                    return true;
                }

                return p.liked_by.includes(sessionQuery.userId);
            },
            sortBy
        });
    };

    selectFromChannel = (channelId: string, sorted = SortedBy.Recent) => {
        // Defaults to most recent
        let sortBy = sortByRecent;

        if (sorted === SortedBy.All) {
            sortBy = sortBySong;
        }

        return this.selectAll({
            filterBy: (p) => {
                const isFromChannel = String(p.channel_id) === channelId;

                if (!isFromChannel) {
                    return false;
                }

                const isSortedByLiked = sorted === SortedBy.Liked;
                if (!isSortedByLiked) {
                    return true;
                }

                return p.liked_by.includes(sessionQuery.userId);
            },
            sortBy
        });
    };
}

export const postsQuery = new PostsQuery(postsStore);

const sortByRecent = (a: Post, b: Post) => +new Date(b.inserted_at) - +new Date(a.inserted_at);
const sortBySong = (a: Post, b: Post) => a.song.localeCompare(b.song);
