import React, { useState } from "react";
import { BaseModal } from "./BaseModal/BaseModal";
import { Channel } from "../../state/channels";

interface ChannelModalProps {
    toggleCreateModal: (show: boolean) => void;
    createChannel: (params: Partial<Channel>) => void;
}

export const ChannelModal: React.FC<ChannelModalProps> = (props) => {
    const [channel, setChannel] = useState({ name: "", description: "" });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setChannel({ ...channel, [name]: value });
    };

    return (
        <BaseModal
            title="New channel"
            body={
                <>
                    <div className="field">
                        <label className="label">Channel name</label>
                        <div className="control">
                            <input
                                className="input"
                                name="name"
                                value={channel.name}
                                onChange={handleInputChange}
                                type="text"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Channel description</label>
                        <div className="control">
                            <textarea
                                className="textarea"
                                name="description"
                                value={channel.description}
                                onChange={handleInputChange}
                                rows={3}
                            ></textarea>
                        </div>
                    </div>
                </>
            }
            actions={
                <div className="actions">
                    <button className="button is-vf" onClick={() => props.toggleCreateModal(false)}>
                        Cancel
                    </button>
                    <button className="button is-vf" onClick={() => props.createChannel(channel)}>
                        Create
                    </button>
                </div>
            }
            onDismiss={() => props.toggleCreateModal(false)}
        />
    );
};
