import { format } from "date-fns";
import React from "react";
import { Comment as IComment } from "../../state/comments/comment.model";

interface CommentProps {
    comment: IComment;
}
export const Comment: React.FC<CommentProps> = ({ comment }) => {
    const user = comment.user.name;
    const date = format(new Date(comment.inserted_at), "d LLL y HH:mm");

    return (
        <div className="comment">
            <p className="comment-text">{comment.message}</p>
            <p className="comment-info">
                {user} - {date}
            </p>
        </div>
    );
};
