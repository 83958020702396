import { EntityState, EntityStore, StoreConfig, EntityUIStore } from "@datorama/akita";
import { Post } from ".";

export interface PostUI {
    hasLoadedComments: boolean;
}

export interface PostsState extends EntityState<Post> {}
export interface PostsUIState extends EntityState<PostUI> {}

@StoreConfig({ name: "posts" })
export class PostsStore extends EntityStore<PostsState> {
    ui!: EntityUIStore<PostsUIState>;

    constructor() {
        super();
        this.createUIStore().setInitialEntityState({ hasLoadedComments: false });
    }
}

export const postsStore = new PostsStore();
