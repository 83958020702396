import { faComment, faHeart } from "@fortawesome/free-regular-svg-icons";
import { faComment as faCommentS, faHeart as faHeartS } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Comment } from "../../state/comments";
import { playerQuery } from "../../state/player";
import { playerService } from "../../state/player/player.service";
import { postsQuery, postsService } from "../../state/posts";
import { Post as IPost } from "../../state/posts/post.model";
import { sessionQuery } from "../../state/session";
import { WebPlaybackPlayer } from "../../types/spotify";
import { Comments } from "../Comment";
import { PlayButton } from "../Player";
import "./post.scss";

interface PostProps {
    post: IPost;
    // TODO: Create Provider for Channel Id
    channelId: string;
    player?: WebPlaybackPlayer;
}
export const Post: React.FC<PostProps> = ({ post: p, player, channelId }) => {
    const [post, setPost] = useState(p);
    const [isPlaying, setPlaying] = useState(false);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        const post$ = postsQuery.selectEntity(post.id).subscribe((post) => {
            setPost(post);
        });

        const currentTrack$ = playerQuery.select().subscribe((player) => {
            const playingTrack = player.track;

            if (!playingTrack) {
                setPlaying(false);
                return;
            }

            setPlaying(player.isPlaying && playingTrack.track === post.track);
        });

        return () => {
            post$.unsubscribe();
            currentTrack$.unsubscribe();
        };
    }, [post.id, post.track]);

    const playSong = () => {
        if (post.track === playerQuery.currentTrack) {
            playerService.play();
        } else {
            const tracks = postsQuery.getFromChannel(channelId).map((p) => p.track);
            const trackIndex = tracks.findIndex((i) => i === post.track);

            playerService.play(tracks.splice(trackIndex));
        }
    };

    const pauseSong = () => {
        if (player) {
            player.pause();
        }
    };

    const toggleComments = () => {
        setShowComments(!showComments);
    };

    const renderComments = showComments && (
        <Comments postId={String(post.id)} channelId={channelId} />
    );

    const [justAdded, setJustAdded] = useState(true);
    useEffect(() => {
        setTimeout(() => setJustAdded(false), 0);
    }, [justAdded]);

    return (
        <div className={justAdded ? "post just-added" : "post"}>
            <div className="info">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <p>
                            Submitted by [ <span className="caps">{post.user.name}</span> ]
                        </p>
                        <p className="message">{post.message}</p>
                        <a href={post.url} target="_blank" rel="noopener noreferrer">
                            {post.url}
                        </a>
                    </div>
                </div>
            </div>

            <div className="track">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="content">
                            <div className="top">
                                <div className="track-info">
                                    <h5 className="bold">{post.song}</h5>
                                    <h6>{post.artists}</h6>
                                </div>
                                <PlayButton
                                    isPlaying={isPlaying}
                                    play={playSong}
                                    pause={pauseSong}
                                />
                            </div>

                            <hr />

                            <Reactions
                                liked_by={post.liked_by}
                                comments={post.comments}
                                postId={post.id}
                                channelId={post.channel_id}
                                toggleComments={toggleComments}
                            />

                            {renderComments}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ReactionsProps {
    liked_by: number[];
    comments: Comment[];
    channelId: string;
    postId: number | string;
    toggleComments: () => void;
}
const Reactions: React.FC<ReactionsProps> = ({
    liked_by,
    comments,
    postId,
    channelId,
    toggleComments
}) => {
    const userId = sessionQuery.userId;
    const heartIcon = liked_by.includes(userId) ? faHeartS : faHeart;
    const commentsIcon = comments.length ? faCommentS : faComment;

    const like = () => postsService.like(channelId, String(postId));

    return (
        <div className="reactions">
            <div className="likes">
                <span onClick={like}>
                    <FontAwesomeIcon icon={heartIcon} />
                </span>{" "}
                [ <span className="count">{liked_by.length}</span> ]
            </div>
            <div className="comments">
                <span onClick={toggleComments}>
                    <FontAwesomeIcon icon={commentsIcon} />
                </span>{" "}
                [ <span className="count">{comments.length}</span> ]
            </div>
        </div>
    );
};
