import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { channelsService } from "../../../state/channels";
import { userService } from "../../../state/users/user.service";
import { useDebounce } from "../../../utils";
import { hightlight } from "../../../utils/highlight";
import { BaseModal } from "../BaseModal/BaseModal";
import "./invite-modal.scss";

interface SearchResult {
    id: number;
    name: string;
    hasJoined: boolean;
}

interface AddUserModalProps {
    channelId: string;
    toggleAddUserModal: (show: boolean) => void;
    inviteModal: () => void;
}
export const AddUserModal: React.FC<AddUserModalProps> = ({
    toggleAddUserModal,
    channelId,
    inviteModal
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState<SearchResult[]>([]);
    const [isLoading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value.toUpperCase());
        setLoading(true);
    };

    const debouncedTerm = useDebounce(searchTerm, 600);

    useEffect(() => {
        if (debouncedTerm) {
            userService
                .search<SearchResult[]>({ term: debouncedTerm, channelId })
                .then((res) => {
                    setResults(res.data);
                    setLoading(false);
                })
                .catch(() => setLoading(false));
        } else {
            setResults([]);
            setLoading(false);
        }
    }, [debouncedTerm, channelId]);

    const renderBody = (
        <>
            <div className="form">
                <div className="field">
                    <label className="label">WHO ARE YOU LOOKING FOR?</label>
                    <div className={isLoading ? "control is-loading" : "control"}>
                        <input
                            className="input"
                            value={searchTerm}
                            onChange={handleInputChange}
                            type="text"
                            autoComplete="off"
                            autoFocus
                        />
                    </div>
                </div>
            </div>
            <Resuls
                results={results}
                searchTerm={debouncedTerm}
                channelId={channelId}
                inviteModal={inviteModal}
            />
        </>
    );

    return (
        <BaseModal
            body={renderBody}
            onDismiss={() => toggleAddUserModal(false)}
            hideBackground={true}
            className="invite-modal"
        />
    );
};

interface ResultsProps {
    results: SearchResult[];
    searchTerm: string;
    channelId: string;
    inviteModal: () => void;
}
const Resuls: React.FC<ResultsProps> = ({ results, searchTerm, channelId, inviteModal }) => {
    const mapResults = results.map((r) => (
        <Result key={r.id} result={r} searchTerm={searchTerm} channelId={channelId} />
    ));

    const renderInvite = searchTerm.length > 0 && <InviteUser inviteModal={inviteModal} />;

    return (
        <div className="results">
            {mapResults}
            {renderInvite}
        </div>
    );
};

interface ResultProps {
    result: SearchResult;
    searchTerm: string;
    channelId: string;
}
const Result: React.FC<ResultProps> = ({ result, searchTerm, channelId }) => {
    const hightlighted = hightlight(searchTerm, result.name, "span");

    return (
        <div className="result">
            <p dangerouslySetInnerHTML={{ __html: hightlighted }}></p>
            <ResultAction result={result} channelId={channelId} />
        </div>
    );
};

interface ResultActionProps {
    result: SearchResult;
    channelId: string;
}
const ResultAction: React.FC<ResultActionProps> = ({ result, channelId }) => {
    const [loading, setLoading] = useState(false);
    const [hasJoined, setHasJoined] = useState(result.hasJoined);

    if (hasJoined) {
        return (
            <Link to="#" className="button is-small is-success">
                <FontAwesomeIcon icon={faCheck} />
            </Link>
        );
    }

    if (loading) {
        return (
            <Link to="#" className="button is-small is-loading">
                Add
            </Link>
        );
    }

    const inviteUser = () => {
        setLoading(true);

        channelsService
            .inviteUser(channelId, String(result.id))
            .then(() => {
                setLoading(false);
                setHasJoined(true);
            })
            .catch(() => setLoading(false));
    };

    return (
        <Link to="#" className="button is-small" onClick={inviteUser}>
            Add
        </Link>
    );
};

interface InviteUserProps {
    inviteModal: () => void;
}
const InviteUser: React.FC<InviteUserProps> = ({ inviteModal }) => {
    return (
        <div className="result" onClick={inviteModal}>
            <p>Invite a new user...</p>
        </div>
    );
};
