import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { playerQuery, playerService } from "../../state/player";
import { sessionQuery } from "../../state/session";
import { WebPlaybackPlayer, WebPlaybackState } from "../../types/spotify";
import { loadScript } from "../../utils";

export function usePlayer(
    initialState?: WebPlaybackPlayer
): [WebPlaybackPlayer | undefined, Function] {
    const [player, setPlayer] = useState<WebPlaybackPlayer | undefined>(initialState);

    const load = async () =>
        await loadScript({
            defer: true,
            id: "spotify-player",
            source: "https://sdk.scdn.co/spotify-player.js"
        });

    useEffect(() => {
        const token$ = sessionQuery
            .select((session) => session.user && session.user.spotify_token)
            .subscribe((token) => {
                if (token) {
                    load();
                }
            });

        return () => token$.unsubscribe();
    }, []);

    // @ts-ignore
    window.onSpotifyWebPlaybackSDKReady = () => {
        const name = "VF Player";
        const token = sessionQuery.spotifyToken;

        // @ts-ignore
        setPlayer(new window.Spotify.Player({
            getOAuthToken: (cb: (token: string) => void) => {
                cb(token);
            },
            name
        }) as WebPlaybackPlayer);
    };

    const updatePlayerState = (state: WebPlaybackState | null) => {
        if (!state) {
            playerService.updatePlayer({ isPlaying: false, track: undefined });
            return;
        }

        console.log(state);

        const { current_track: currentTrack } = state.track_window;
        const song = currentTrack.name;
        const artists = currentTrack.artists.map((a) => a.name).join(", ");
        const track = currentTrack.id;
        const isPlaying = !state.paused;
        const shuffle = state.shuffle;

        playerService.updatePlayer({ isPlaying, track: { artists, song, track }, shuffle });
    };

    useEffect(() => {
        if (!player) {
            return;
        }

        player.addListener("ready", ({ device_id }) => {
            playerService.updatePlayer({ deviceId: device_id });
        });

        player.addListener("not_ready", () => toast("Something went wrong.", { type: "error" }));

        player.addListener("player_state_changed", updatePlayerState);

        player.addListener("initialization_error", ({ message }) =>
            toast(message, { type: "error" })
        );
        player.addListener("authentication_error", ({ message }) =>
            toast(message, { type: "error" })
        );
        player.addListener("account_error", ({ message }) => toast(message, { type: "error" }));
        player.addListener("playback_error", ({ message }) => toast(message, { type: "error" }));

        player.connect();

        const updatePlayer = setInterval(() => {
            if (playerQuery.deviceId) {
                player.getCurrentState().then(updatePlayerState);
            }
        }, 1000);

        return () => {
            player.removeListener("ready");
            player.removeListener("not_ready");
            player.removeListener("player_state_changed");
            player.removeListener("initialization_error");
            player.removeListener("authentication_error");
            player.removeListener("account_error");
            player.removeListener("playback_error");
            clearInterval(updatePlayer);
        };
    }, [player]);

    return [player, setPlayer];
}
