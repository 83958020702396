import { EntityState, EntityStore, StoreConfig, EntityUIStore } from "@datorama/akita";
import { Channel } from "./channel.model";
import { SortedBy } from "../posts";

export interface ChannelUI {
    sortBy: SortedBy;
}

export interface ChannelsState extends EntityState<Channel> {}
export interface ChannelUIState extends EntityState<ChannelUI> {}

@StoreConfig({ name: "channels" })
export class ChannelsStore extends EntityStore<ChannelsState> {
    ui!: EntityUIStore<ChannelUIState>;

    constructor() {
        super();
        this.createUIStore().setInitialEntityState({ sortBy: SortedBy.Recent });
    }
}

export const channelsStore = new ChannelsStore();
