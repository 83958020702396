import { Query } from "@datorama/akita";
import { PlayerState, PlayerStore, playerStore } from "./player.store";

export class PlayerQuery extends Query<PlayerState> {
    get isPlaying(): boolean {
        return this.getValue().isPlaying;
    }

    get isShuffle(): boolean {
        return this.getValue().shuffle;
    }

    get deviceId(): string | undefined {
        return this.getValue().deviceId;
    }

    get currentTrack(): string | undefined {
        const currentTrack = this.getValue().track;

        if (!currentTrack) {
            return currentTrack;
        }

        return currentTrack.track;
    }

    constructor(protected store: PlayerStore) {
        super(store);
    }
}

export const playerQuery = new PlayerQuery(playerStore);
