import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { User } from "../../state/users/user.model";
import { Channel, channelsQuery, channelsService } from "../../state/channels";
import { sessionService } from "../../state/session";
import Logo from "../Logo";
import { ChannelModal } from "../Modal";
import "./navbar.scss";
import { getNames, UserIcon } from "./UserIcon";
import history from "../../history";

interface NavbarProps {
    user: User | undefined;
}

export const Navbar: React.FC<NavbarProps> = (props) => {
    const [createModalVisible, toggleCreateModal] = useState(false);
    const [channels, setChannels] = useState<Channel[]>([]);

    useEffect(() => {
        if (!channels.length) {
            channelsService.getAll();
        }

        const channels$ = channelsQuery.selectAll().subscribe((c) => {
            if (channels.length !== c.length) {
                setChannels(c);
            }
        });

        return () => channels$.unsubscribe();
    });

    const name = getNames((props.user && props.user.name) || "")[0];
    const foto = (props.user && props.user.image) || "";

    const logout = () => {
        sessionService.unauthenticate();
    };

    const createChannel = (params: Partial<Channel>) => {
        channelsService.create(params).then((channelId) => {
            toggleCreateModal(false);
            history.push(`/c/${channelId}`);
        });
    };

    const createModal = createModalVisible && (
        <ChannelModal toggleCreateModal={toggleCreateModal} createChannel={createChannel} />
    );

    const renderChannels = channels.map((channel) => {
        return (
            <React.Fragment key={channel.id}>
                <NavLink className="navbar-item" to={`/c/${channel.id}`} activeClassName="active">
                    <span className="description-item">{channel.name}</span>
                    <UserIcon name={channel.name} />
                </NavLink>
                <span className="nav-item-border"></span>
            </React.Fragment>
        );
    });

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <Link className="navbar-item" to="/">
                    <Logo />
                </Link>
            </div>
            <span className="nav-item-border"></span>

            <div className="navbar-menu">
                <div className="navbar-start">
                    <NavLink className="navbar-item" to="/" activeClassName="active" exact>
                        <span className="description-item">{name}</span>
                        <UserIcon name={name} image={foto} color="purple" />
                    </NavLink>
                    <span className="nav-item-border"></span>

                    {renderChannels}

                    <NavLink
                        className="navbar-item"
                        to="#"
                        onClick={() => toggleCreateModal(true)}
                        activeClassName="active"
                    >
                        <span className="description-item">New Channel</span>
                        <UserIcon name="+" color="green" />
                    </NavLink>
                    <span className="nav-item-border"></span>
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="button is-black is-fullwidth logout" onClick={logout}>
                            Log out
                        </div>
                    </div>
                </div>
            </div>

            {createModal}
        </nav>
    );
};
