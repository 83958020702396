import { post, get } from "../../api";
import { validateEmail } from "../../utils";

interface SearchTerm {
    term: string;
    channelId: string;
}

export class UserService {
    search<T>(term: SearchTerm) {
        return post<T>("/search", term);
    }

    async checkEmail(email: string, channelId: string): Promise<boolean> {
        if (!validateEmail(email)) {
            return false;
        }

        const res = await get<boolean>(`/channels/${channelId}/email/${email}`);
        return res.data;
    }

    async invite(email: string, channelId: string) {
        const res = await post<boolean>(`/channels/${channelId}/email/${email}`);
        return res.data;
    }
}

export const userService = new UserService();
