import React, { useEffect, useState } from "react";
import { Comment as IComment, commentsQuery, commentsService } from "../../state/comments";
import { postsQuery } from "../../state/posts";
import { Comment } from "./Comment";
import { CommentInput } from "./CommentInput";
import "./comments.scss";

interface CommentsProps {
    postId: string;
    channelId: string;
}
export const Comments: React.FC<CommentsProps> = ({ channelId, postId }) => {
    const [comments, isLoading] = useComments(channelId, postId);

    const renderComments = !isLoading && comments.map((c) => <Comment key="c.id" comment={c} />);
    const renderLoadingComments = isLoading && (
        <div className="loading-comments">Loading comments....</div>
    );

    return (
        <div className="comments-box">
            <CommentInput channelId={channelId} postId={postId} />

            {renderLoadingComments}
            {renderComments}
        </div>
    );
};

function useComments(
    channelId: string,
    postId: string,
    initialState: IComment[] = []
): [IComment[], boolean] {
    const [comments, setComments] = useState(initialState);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const loading$ = postsQuery.ui
            .selectEntity(postId, "hasLoadedComments")
            .subscribe((loaded) => {
                if (!loaded) {
                    commentsService.getForPost(channelId, postId);
                }
                setLoading(!loaded);
            });

        return () => loading$.unsubscribe();
    }, [postId, channelId]);

    useEffect(() => {
        const comments$ = commentsQuery
            .selectFromPost(String(postId))
            .subscribe((c) => setComments(c));

        return () => comments$.unsubscribe();
    }, [postId]);

    return [comments, isLoading];
}
