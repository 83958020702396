import React from "react";
import { BaseModal } from "./BaseModal/BaseModal";

interface DeleteModalProps {
    toggleDeleteModal: (show: boolean) => void;
    deleteChannel: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = (props) => {
    return (
        <BaseModal
            title="Delete channel"
            body={<p>Are you sure you want to delete this channel?</p>}
            actions={
                <div className="actions">
                    <button className="button is-vf" onClick={() => props.toggleDeleteModal(false)}>
                        Cancel
                    </button>
                    <button className="button is-vf" onClick={() => props.deleteChannel()}>
                        Yes, delete it!
                    </button>
                </div>
            }
            onDismiss={() => props.toggleDeleteModal(false)}
        />
    );
};
