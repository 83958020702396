import React, { useEffect, useState } from "react";
import { Route, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Layout } from "../../components/Layout";
import Loading from "../../components/Loading";
import history from "../../history";
import { sessionQuery, sessionService } from "../../state/session";
import { Channel } from "../Channel";
import { Home } from "../Home";
import { Login } from "../Login";

const App: React.FC = () => {
    const [isAuthenticating, setAuthenticating] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        setAuthenticating(true);

        sessionService
            .getUser()
            .then((res) => sessionService.authenticate(res.data))
            .catch(() => history.push("/login" + history.location.search))
            .then(() => setAuthenticating(false));

        const session$ = sessionQuery.select().subscribe((state) => {
            setAuthenticated(state.authenticated);
        });

        return () => session$.unsubscribe();
    }, [isAuthenticated]);

    if (isAuthenticating) {
        return <Loading />;
    }

    return (
        <Router history={history}>
            <>
                <Route exact path="/login" component={Login} />

                <Layout>
                    <Route exact path="/" component={Home} />
                    <Route path="/c/:id" component={Channel} />
                </Layout>

                <ToastContainer position="bottom-right" />
            </>
        </Router>
    );
};

export default App;
