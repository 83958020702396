import { StoreConfig, Store } from "@datorama/akita";

export interface PlayerState {
    deviceId?: string;

    tracks?: string[];
    track?: Track;
    isPlaying: boolean;
    shuffle: boolean;
}

export interface Track {
    artists: string;
    song: string;
    track: string;
}

@StoreConfig({ name: "player" })
export class PlayerStore extends Store<PlayerState> {
    constructor() {
        super(createInitialState());
    }
}

export const playerStore = new PlayerStore();

function createInitialState(): PlayerState {
    return {
        isPlaying: false,
        shuffle: false
    };
}
